(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/// <reference path="definitions/jquery.d.ts" />
/// <reference path="modules/navPrimary.ts" />
/// <reference path="modules/utils.ts" />
"use strict";
// Import NPMs
// --------------------------------------------------------------
// jQuery is served by contao
// import jQuery = require('jquery');
//
// let $ = jQuery;
// Primary Navigation
// --------------------------------------------------------------
var navPrimary_1 = require("./modules/navPrimary");
// Nav Primary
var navPrimary = new navPrimary_1.Navigation({
    wrapper: '.nav-primary',
    navItem: '.level_1 li.submenu > a',
    navItemRoot: '.level_1 > li.submenu > a',
    navItemRootClass: 'level_1',
    navItemWrapper: 'li',
    navItemActiveClass: 'open',
    navActiveClass: 'nav_active',
    setBodyClassOnRoot: true,
    toggleBtn: '.nav_toggle'
});
navPrimary.init();
// Nav Offcanvas
var navOffcanvas = new navPrimary_1.Navigation({
    wrapper: '.site-offcanvas',
    navItem: '.level_1 li.submenu > a',
    navItemRoot: '.level_1 > li.submenu > a',
    navItemRootClass: 'level_1',
    navItemWrapper: 'li',
    navItemActiveClass: 'open',
    navActiveClass: 'nav_active',
    setBodyClassOnRoot: false,
    toggleBtn: '.nav_toggle'
});
navOffcanvas.init();
// Equal Heights
// --------------------------------------------------------------
var utils_1 = require("./modules/utils");
var equalHeights = new utils_1.EqualHeights({
    wrapper: '.equal_heights',
    items: '.block',
    breakpoint: 600
});
var featuresHeights = new utils_1.EqualHeights({
    wrapper: '.seminars_teaser',
    items: '.item a',
    breakpoint: 400
});
var heroTextHeights = new utils_1.EqualHeights({
    wrapper: '.site-hero',
    items: '.hero .text_container',
    breakpoint: 0
});
// Square Teaser
// --------------------------------------------------------------
var utils_2 = require("./modules/utils");
var squarePortrait = new utils_2.Square({
    elements: '.teaser_portrait'
});
// Header Search
// --------------------------------------------------------------
var navPrimary_2 = require("./modules/navPrimary");
var navSearch = new navPrimary_2.NavSearch();
// No JS Detect
// --------------------------------------------------------------
var utils_3 = require("./modules/utils");
var jsDetect = new utils_3.JsDetect();

},{"./modules/navPrimary":2,"./modules/utils":3}],2:[function(require,module,exports){
"use strict";
var Navigation = (function () {
    function Navigation(o) {
        this.navOptions = o;
        this.$body = $('body');
    }
    Navigation.prototype.init = function () {
        var _this = this;
        $(_this.navOptions.wrapper).on('click', _this.navOptions.navItem, function () {
            var _self = this, $activeItems = $(_self).closest('ul').find('.' + _this.navOptions.navItemActiveClass), $itemWrapper = $(_self).closest(_this.navOptions.navItemWrapper);
            if (_this.$body.scrollTop() > 0) {
                _this.$body.animate({
                    scrollTop: 0
                }, 'fast');
            }
            if ($itemWrapper.hasClass(_this.navOptions.navItemActiveClass)) {
                $(_self)
                    .closest(_this.navOptions.navItemWrapper)
                    .removeClass(_this.navOptions.navItemActiveClass);
            }
            else {
                $activeItems
                    .removeClass(_this.navOptions.navItemActiveClass);
                $(_self)
                    .closest(_this.navOptions.navItemWrapper)
                    .addClass(_this.navOptions.navItemActiveClass);
            }
            if (_this.navOptions.setBodyClassOnRoot && $itemWrapper.closest('ul').hasClass(_this.navOptions.navItemRootClass)) {
                _this.$body.toggleClass(_this.navOptions.navActiveClass);
            }
            return false;
        });
        $(_this.navOptions.wrapper).on('click', '#close', function () {
            _this.close();
            return false;
        });
        $(document).keyup(function (e) {
            if (e.keyCode === 27) {
                _this.close();
            }
        });
        $(_this.navOptions.wrapper).on('click', _this.navOptions.toggleBtn, function () {
            _this.$body.toggleClass(_this.navOptions.navActiveClass);
            return false;
        });
    };
    Navigation.prototype.close = function () {
        var _this = this;
        _this.$body
            .removeClass(_this.navOptions.navActiveClass)
            .find(_this.navOptions.wrapper)
            .find('.' + _this.navOptions.navItemActiveClass)
            .removeClass(_this.navOptions.navItemActiveClass);
    };
    return Navigation;
}());
exports.Navigation = Navigation;
var NavSearch = (function () {
    function NavSearch() {
        $('.site-header .site-search').prependTo('.site-header .nav-primary');
        $('.site-header .nav-primary').on('click', 'a.search', function (e) {
            $(this).toggleClass('in');
            $(this).closest('.site-header').find('.site-search').fadeToggle('fast', function () {
                $(this).find('input').focus();
            });
            e.preventDefault();
        });
    }
    return NavSearch;
}());
exports.NavSearch = NavSearch;

},{}],3:[function(require,module,exports){
"use strict";
var EqualHeights = (function () {
    function EqualHeights(o) {
        var _this = this;
        this.wrapper = o.wrapper;
        this.items = o.items;
        this.breakpoint = o.breakpoint;
        $(window).load(function () { return _this.setHeights(_this.wrapper, _this.items); });
        $(window).resize(function () { return _this.setHeights(_this.wrapper, _this.items); });
    }
    EqualHeights.prototype.setHeights = function (wrapper, items) {
        var breakpoint = this.breakpoint;
        $(wrapper).each(function () {
            var itemsHeight = 0, $items = $(this).find(items);
            $items.height('auto');
            if ($(window).width() > breakpoint) {
                $items.each(function () {
                    var _item = this, itemHeight = $(_item).height();
                    if (itemHeight > itemsHeight) {
                        itemsHeight = itemHeight;
                    }
                });
                $items.height(itemsHeight);
            }
        });
    };
    return EqualHeights;
}());
exports.EqualHeights = EqualHeights;
var Square = (function () {
    function Square(o) {
        var _this = this;
        this.elements = o.elements;
        this.setHeight(this.elements);
        $(window).resize(function () { return _this.setHeight(_this.elements); });
    }
    Square.prototype.setHeight = function (elements) {
        $(elements).each(function (i, elem) {
            $(elem).height($(elem).width());
        });
    };
    return Square;
}());
exports.Square = Square;
var JsDetect = (function () {
    function JsDetect() {
        $('body').removeClass('nojs');
    }
    return JsDetect;
}());
exports.JsDetect = JsDetect;

},{}]},{},[1]);
